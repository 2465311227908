.properties-categories-short-title{
  font-family: @font-title;
  font-size: 30px;
  font-weight: 500;
  color: #000 !important;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.properties-categories-short{
  color: #000;
  display: inline-block;
  font-size: 13px;
  margin-right:15px;
  background: rgba(255,255,255,.2);
  border-radius: 4px;
  padding:5px;

  &:last-of-type{
    //margin-right:0;
  }

  &:hover{
    // color: @mycolor-red;
    color: #000;
    text-decoration: none;
    background: rgba(255,255,255,.5);
    i{
      color: #000;
      // background: @mycolor-red;

    }
  }

  i{
    display: block;
    width: 100px;
    height: 70px;
    line-height: 70px;

    text-align: center;

    &:before{
      font-size: 45px;
    }
  }
}

@media (max-width: @screen-sm){
  .half-splash-content .properties-categories-short-wrapper {
   // display:none;
  }
}