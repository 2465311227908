
  h2.module-latestproperties-title{
    margin-top:20px;
    margin-bottom:40px;
    font-family: @font-title;
    font-size:56px;
    font-weight: bold;
    text-align:center;
    line-height: 80%;


    span{
      color: @mycolor-red;
      font-size: 40px;
      font-weight: 500;
      position:relative;
      background: #fff;
    //  display:block;

    }
  }


  .home-about-wrapper{
    background: #fff;
    max-width: 700px;
    margin:0 auto;
    padding:60px;
    font-size:15px;
    font-weight: 300;
    line-height: 180%;
    margin-top:-70px;

  }

  h2.bigred{
    font-family: @font-title;
    font-size:56px;
    font-weight: bold;
  //  text-align:left;
    line-height: 80%;
    text-align: center;
  //  display: inline-block;
    padding-right: 20px;
    margin:20px auto 40px auto;

    span{
      color: @mycolor-red;
      font-size: 40px;
      font-weight: 500;
      position:relative;
      background: #fff;
    //  display:block;
      text-align:right;
    }
  }

  .bigredtitle h2{
    font-family: @font-title;
    font-size:56px;
    font-weight: bold;
    //text-align:left;
    line-height: 80%;
    //display: inline-block;
    padding-right: 20px;
    text-align: center;

    span{
      color: @mycolor-red;
      font-size: 40px;
      font-weight: 500;
      position:relative;
     // background: #fff;
      //display:block;
      text-align:right;

    }
  }


.halfsplash-home{
 // min-height: 600px;
  text-align:center;
  padding-top: 200px;
  padding-bottom: 100px;
  h1{
    font-size: 25px;
    text-transform: uppercase;
    color:@brand-color !important;
    background: fade(#fff, 50);
    display:inline-block;
    padding: 20px 30px;
    min-width: 665px;
    margin-bottom:10px;
    font-weight: 500;

  }
}


.ppt-white-box{
  background: #fff;
  padding: 40px;
  margin-top:20px;
  h1,h2,h3{
    margin-top:0 !important;
    padding-top:0 !important;
  }
}

.white-box{
  background: #fff;
  padding: 20px;
}

.page-container .sortable-container{
  background: #fff;
  padding: 20px;
}

.row.latest-properties.propertysmall{
  margin: 0;
}


.footer-searchshort{
 padding: 8px 0 8px;
  .search-form-wrapper{
    margin-bottom:-20px;
  }
}

.btn-success, .btn-primary{
  background-color: @brand-color;
  border-color: @brand-color;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  margin: 15px 0;
  &:hover, &:focus,  &:active:hover, &:active:focus, &:active{
    background-color: fade(@brand-color, 90%);
    border-color: @brand-color;
  }
}

.owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-prev:hover,.owl-theme .owl-nav .owl-next, .owl-theme .owl-nav .owl-next:hover{
    color: #000;
   background: none;

  &:before{
    font-size: 60px;
  }
}
.homepage .latest-properties.owl-theme .owl-nav .owl-prev{
  left: -80px;
}

.homepage .latest-properties.owl-theme .owl-nav .owl-next{
  right: -80px;

}

.half-splash .newsletter-module{
  max-width: 400px;
  margin:0 auto;
}

.newsletter-module input[type=submit].btn-block{
    width: auto;
  margin:0 auto;
}

.redline-delim{
  margin: 20px auto;
  div{
    width: 250px;
    height:1px;
    background-color: @brand-color;
    margin:0 auto;
  }

}

.news-box, .article-wrapper{
  margin-top: 30px;
}

.home-sellproperty{
  margin-top: 80px;
  color: #fff;
  h2{
    margin-bottom:0;
    padding:5px;
  }
  a{
    color: #fff;

    i:before{
      font-size: 60px;
    }
    &:hover{
      opacity: .8;
      text-decoration: none;
    }
  }
}

.contact-social-item{
  margin-right: 10px;
}

@media(max-width: @screen-md)
{

  h2.module-latestproperties-title{
    margin-top:0;
  }
  .halfsplash-home {
    padding-top: 100px;
    padding-bottom: 50px;
    h1 {
      min-width: 0;
      font-size: 18px;
      display: block;
    }
  }

}

  @media(max-width: @screen-sm)
  {
    h2.module-latestproperties-title, h2.bigred, .bigredtitle h2{
      font-size:40px;
      span{
        font-size: 30px;
      }

      &:before{
        display:none;

      }
    }

  }

