.imagetext-module{
  height: 280px;
  //padding:45px;
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
}

.imagetext-module-title{
  position:relative;

  h3{
    font-weight: 400;
  }
}

.imagetext-black .imagetext-module-text-wrapper{
  background: fade(#000, 80%);
}

.imagetext-module-text-wrapper{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  text-align:center;
  padding:0;
  //#gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));
  background: fade(@brand-color2, 80%);
  display: flex;
  align-items: center;
  justify-content: center;

  h1,h2,h3,h4, h5, p{
    color: #fff;
    text-align:center;
    font-weight: bold;
  }

  h1,h2,h3{
    font-size: 30px;
    font-weight: 400;
  }

  &:hover{
    opacity: .8;
  }



}

.imagetext-module-title{
  display:inline-block;
  padding:20px 25px;
  margin:0;
  border: 1px solid #fff;
  h3{
    margin:0;
    padding:0;
  }

}

.imagetext-module-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}