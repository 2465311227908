#mm-mobilenav {
//  background: @brand-color;
  .logo-mobile{
    max-width: 250px;
    text-align:center;
    margin: 0 auto;
    img{
      margin:0 auto;
    }
  }

  .mm-listview {
    display: block;
    margin: 0 !important;

    .sub-menu {
      display: block;
      position: relative;
      background: none;
      width: auto;
    }

    li {
      float: none !important;
      display:block;
      border-right:0;
      padding:10px 0;
      a {

        width: 100%;
        float: none;
        text-align: center;
      }
    }
  }
}
#mobile-header{
  position: absolute;
  top:10px;
  left:10px;

 //margin: 10px;
  z-index:100;
}

.logo-mobile{
  img{
    max-height: 100px;
  }
}