.search-form-wrapper h4{
  font-size: 20px;
  font-weight: bold;
  color: #111;
}

.shortsearchform {

  .form-group{
    margin-right:3px;

    &:first-child{
      .input-blue.form-control{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
  .selectize-dropdown-content{
    text-align:left;
  }
  .form-group{
    vertical-align:top;
  }
  .selectize-input, .selectize-control.multi .selectize-input.has-items {
    padding: 12px;
    height: 50px;
    border-radius:0;
   // margin-top:7px;
    border:none;
    min-width: 400px;
   // max-width: 400px;
    text-align: left;
    input{
      line-height: 25px !important;
    }
  }

  .search-inner{
    display: table;
    table-layout: fixed;
    width: 100%;
    vertical-align: top;
  }

  .aditional-search{

    height: 50px;
    display: inline-block;
    vertical-align:top;
    .btn{
      margin:0 !important;
      background:@brand-color2;
      font-size: 14px;
      height: 50px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

::placeholder{
  color: #999;
}

.divider-inpagesearch{
 // padding:0;

  .search-form-wrapper{
    padding:15px 15px 0 15px;
   // background:@brand-color3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .btn{
    border-bottom:none !important;
  }

  .search-form-wrapper .search-inner input.form-control{
    height:35px;
    padding:5px 10px;
  }

  .search-form-wrapper .search-inner .btn{
    height:35px;
    padding:5px 10px;
  }

  .search-form-wrapper .btn{
    background: @brand-color2;
    margin-bottom:15px !important;
  }
}

.half-splash{
  .search-form-wrapper{
    padding: 0 30px 30px 30px;
    text-align:center;
    width: 90%;
    margin:0 auto;
    .search-inner{
   //   background: rgba(0,0,0,.4);
      border: none;
    }


  }

  .search-form-wrapper .search-inner input.btn-primary{
    width: auto;
    margin: 0 auto;
    //margin-top: 20px;
    font-size: 14px;
  }


  .full-group{
    width: 100%;
    position:relative;
    .form-control{
      width: 100%;
    }

    .selectize-input{
      min-height: 40px;
      font-size: 16px;
      padding: 12px 6px 12px 6px;
      height: 64px;
      line-height: 40px;



    }
  }

  .search-icon{
    position:absolute;
    top: 6px;
    left: 10px;
    z-index: 1000;
    i{
      color: @brand-color;
      font-size: 26px;
    }
  }
}
.search-form-wrapper{

  //background:rgba(255,255,255,.6);
  //background:rgba(0,0,0,.3);
  //background: #fff;
  //background: #fff;
//  padding: 15px 15px 0 15px;

  .btn-primary{
    background: @brand-color;
    border:none;

    &:active{
      background: darken(@brand-color, 10%);
    }
  }
}

.search-form-vertical{
  .btn-primary{
    background: @mycolor-red;
    border:none;

    &:active{
      background: darken(@mycolor-red, 10%);
    }
  }
}


@media(max-width: @screen-sm) {
  .half-splash {
    .search-form-wrapper {
      margin: 15px auto 15px;
     width: 100%;
      padding: 20px;
    }

    .short-location{
      width: 70%;
     float:left;
    }
  }
}

.search-inner .input-short{
    height: 50px;
  border-radius:0;
  margin-right:3px;
  button{
    line-height: 25px;
    border-radius:0;

    .filter-option{
      padding-right: 10px;
    }
  }
}
.search-inner .input-red{
  &.form-control{
    background: @mycolor-red;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

.search-inner .input-blue{
  &.form-control{
    background: @mycolor-blue;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}


.search-inner .input-transparent{
  &.form-control{
    background: none;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

@media(max-width: @screen-sm){

  .shortsearchform .selectize-input, .shortsearchform .selectize-control.multi .selectize-input.has-items {
      min-width: 0;
    }

  .shortsearchform .aditional-search{
    //background: none;
    float:right;
    width:28%;
  }

}