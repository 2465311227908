.search-wrapper{
  padding-top:30px;
}

.mobile-filtering-button{
  background: @brand-color2;
  padding:15px 30px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;

  &:hover, &:active{
    text-decoration: none;
    color: #fff;
  }
}